html,
body {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
}

@media print {
  body {
    overflow: visible;
    height: auto;
    print-color-adjust: exact;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
select {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

ul {
  list-style: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

* {
  box-sizing: border-box;
}

.swal2-container {
  z-index: 9999;
}
